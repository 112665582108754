.profile-preview {

  mh-tags-output .ant-tag {
    max-width: 100%;
  }

  .col-summary {
    white-space: pre-line;
  }

  &__info {
    display: flex;
    gap: 20px;
    margin-bottom: 30px;
  }

  &__info-col {
    flex: 1;
  }

  &__info-top {
    display: flex;
    justify-content: space-between;
  }

  &__avatar {
    position: relative;
  }

  &__name {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 0;
  }

  &__tag {
    margin-left: 2px;
    transform: translateY(2px);
  }

  &__welcome-message-title {
    margin-top: 16px;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 20px;
    line-height: 120%;
  }

  &__nav {
    margin-bottom: 10px;

    .ant-anchor {
      display: flex;
      flex-wrap: wrap;
    }

    .ant-anchor-link {
      padding: 5px 10px;

      .anticon {
        font-size: 32px;
      }
    }

    .ant-anchor-ink {
      display: none;
    }
  }

  &__section {
    border-radius: 8px;
    background: #FFF;
    box-shadow: 0 0 30px 0 rgba(111, 196, 194, 0.20);
    padding: 40px;

    & + & {
      margin-top: 30px;
    }

    h4, p {
      margin: 0;
    }
  }

  &__section-content-row {
     & + & {
       margin-top: 24px;
     }
  }

  &__media-section {
    padding: 30px 0 40px;
  }

  &__media {
    video {
      max-width: 570px;
      width: 100%;
      border-radius: 8px;
    }
  }

  &__section-title {
    .anticon {
      color: @primary-color;
      margin-right: 10px;
    }
  }

  &__section-content {
    mh-tags-output {
      margin-top: 1px;
    }
  }

  &__item-label {
    text-align: right;
    padding-right: 20px;
    padding-bottom: 24px;

    + * {
      padding-bottom: 24px;

      &:last-child {
        padding-bottom: 0;
      }
    }

    &:nth-last-child(2) {
      padding-bottom: 0;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 10px 0 25px;
    font-size: 18px;
    line-height: 24px;
    color: #6FC4C2;

    &:after,
    &:before {
      transform: translateY(0);
    }
  }
}

/* Hide the profile preview section if it has empty content */
.profile-preview__section:has(.profile-preview__section-content:empty) {
  display: none;
}

/* Hide the profile preview section if the profile card body's ant-row is empty */
.profile-preview__section:has(.profile-card__body .ant-row:empty) {
  display: none;
}

/* Hide the anchor links in the profile preview navigation */
.profile-preview__nav .ant-anchor-link {
  display: none;
}

/* Show the anchor links if the corresponding section content is not empty */
:has(#about-me .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#about-me"],
:has(#personality .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#personality"],
:has(#desired-job .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#desired-job"],
:has(#education .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#education"],
:has(#experience .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#experience"],
:has(#files .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#files"],
:has(#references) .ant-anchor-link[nzhref="#references"],
:has(#data-space) .ant-anchor-link[nzhref="#data-space"],
:has(#profile-info .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#profile-info"],
:has(#comments) .ant-anchor-link[nzhref="#comments"],
:has(#contact-person .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#contact-person"],
:has(#company-name .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#company-name"],
:has(#diversities .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#diversities"],
:has(#compliance-kpi .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#compliance-kpi"],
:has(#invoice-details .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#invoice-details"],
:has(#talent-stages .profile-preview__section-content:not(:empty)) .ant-anchor-link[nzhref="#talent-stages"],
:has(#comments-from-jo mh-account-comments-from-jo:not(:empty)) .ant-anchor-link[nzhref="#comments-from-jo"] {
  display: block;
}

@media (max-width: 991px) {
  .profile-preview {
    &__name {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 5px;
      position: relative;
    }

    &__sign-in-as {
      position: absolute;
      right: -25px;
    }

    &__info {
      flex-direction: column;
      align-items: center;
      text-align: center;
      margin-bottom: 10px;

      font-weight: 400;
      font-size: 14px;
      line-height: 157%;

      p {
        margin-bottom: 2px;
      }
    }

    &__info-top {
      display: inline-flex;
    }

    &__action {
      position: absolute;
      right: -95px;
      top: 50%;
      transform: translateY(-50%);
    }

    &__section {
      box-shadow: none;
      padding: 0;

      & + & {
        margin-top: 15px;
      }
    }

    &__section-content-row {
      & + & {
        margin-top: 15px;
      }
    }

    &__media-section {
      padding: 15px 0;
    }

    &__section-title {
      font-weight: 400;
      font-size: 20px;
      line-height: 120%;
      text-align: center;
      padding: 10px 0;

      .anticon {
        margin-right: 5px;
      }
    }

    &__nav {
      margin-bottom: 15px;
      .ant-anchor {
        justify-content: center;
      }
    }

    &__item-label {
      text-align: left;
      padding-left: 0;
      padding-bottom: 3px;

      + * {
        padding-bottom: 15px;

        font-weight: 400;
        font-size: 14px;
        line-height: 143%;
      }
    }

    &__key-summary {
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 16px;
      line-height: 137%;

      h3 {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 18px;
        line-height: 122%;
      }
    }

    &__number-of-employees {
      display: block;
    }

    .ant-divider-horizontal.ant-divider-with-text {
      font-weight: 500;
      font-size: 16px;
      line-height: 125%;
      margin-top: 0;
    }
  }
}
